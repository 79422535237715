import React, {Component} from 'react';
import CancerTypeTraits from './cancer_type_traits';
import DiagnosticsAndGenes from './diagnostics_and_genes';
import TumorTypeTraits from './tumor_type_traits';
import Form from '../form';
import Select2 from '../select2';
import Loading from '../loading';
import request from '../../request';
import Stadox from '../../stadox';

export default class SearchCancerType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ctx = Stadox.subscribe(this);
    this.changeOption = this.changeOption.bind(this);
    this.onChangeDiagnosticsAndGenes = this.onChangeDiagnosticsAndGenes.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
  }

  componentDidMount() {
    let cancer_type_id = this.props.query.cancer_type_id;
    if (cancer_type_id) {
      this.fetchCancerType(cancer_type_id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.query.cancer_type_id || this.props.query.cancer_type_id === prevProps.query.cancer_type_id) return;
    this.fetchCancerType(this.props.query.cancer_type_id);
  }

  cancerTypeChanged(cancer_type_id) {
    if (cancer_type_id === this.props.query.cancer_type_id) return;

    this.fetchCancerType(cancer_type_id, cancer_type => {
      let {query, filters, onChange, onChangeFilters} = this.props;

      query.cancer_type_id = cancer_type_id;
      query.cancer_category_id = cancer_type.cancer_category.id
      query.options = {};

      if (cancer_type.cancer_category.tumor_type !== query.tumor_type_id) {
        query.tumor_type_id = cancer_type.cancer_category.tumor_type;
        query.tumor_type = {};
      }

      onChange("cancer_type", query, 'cancerTypeChangedCallback');
    });

  }

  fetchCancerType(cancer_type_id, callback) {
    let url = "/api/cancer_types/" + cancer_type_id;
    this.setState({loading: true});
    request("GET", url).then(cancer_type => {
      this.setState({cancer_type, loading: false});
      this.ctx.change({cancer_type_id, cancer_type_diagnostics_and_genes: cancer_type.diagnostics_and_genes});
      if (callback) callback(cancer_type);
    });
  }

  // buildDefaultTumorTypeOptions(cancer_type) {
  //   let cancer_category = cancer_type.cancer_category;
  //   let tumor_type = cancer_category.tumor_type;
  //   let tumor_type_key = tumor_type === 'S' ? 'solid' : 'liquid';
  //   let data = PopulationData.cancer_type[tumor_type_key];

  //   let opt = {};
  //   for (let section of Object.keys(data)) {
  //     for (let question of Object.keys(data[section].options)) {
  //       if (data[section].options[question].default) {
  //         if (!opt[section]) opt[section] = {};
  //         opt[section][question] = true;
  //       }
  //     }
  //   }

  //   let tumor_type_options = {};
  //   tumor_type_options[tumor_type_key] = opt;
  //   return tumor_type_options;
  // }

  changeOption(trait, opt, event) {
    let value = event.target.checked;
    let {query, onChange} = this.props;
    let {options} = query;
    if (!options[trait.id]) {
      options[trait.id] = {};
    }

    let opt_id = opt.id || opt;
    options[trait.id][opt_id] = value;
    CancerTypeTraits.evaluateSmartEmtpyState(options[trait.id], value);

    query.options = options
    onChange("cancer_type", query);
  }

  changeTumorTypeOption(tumor_type_id, key, opt_id, event){
    let value = event.target.checked;
    let {query, onChange} = this.props;
    let {tumor_type} = query;

    if (!tumor_type[tumor_type_id]) {
      tumor_type[tumor_type_id] = {}
    }

    if (!tumor_type[tumor_type_id][key]) {
      tumor_type[tumor_type_id][key] = {};
    }


    tumor_type[tumor_type_id][key][opt_id] = value;
    CancerTypeTraits.evaluateSmartEmtpyState(tumor_type[tumor_type_id][key], value);

    query.tumor_type = tumor_type;
    onChange("cancer_type", query);
  }

  onChangeDiagnosticsAndGenes(cancer_type_diagnostics_and_genes) {
    let {filters, onChangeFilters} = this.props;
    filters.cancer_type_diagnostics_and_genes = cancer_type_diagnostics_and_genes;
    onChangeFilters(filters);
  }

  onChangeFilter(name, value) {
    let {filters, onChangeFilters} = this.props;
    filters[name] = value;
    onChangeFilters(filters);
  }

  renderTumorType() {
    let {cancer_type} = this.state;
    let {tumor_type} = this.props.query;

    let cancer_category = cancer_type.cancer_category;
    let tumor_type_key = cancer_category.tumor_type === 'S' ? 'solid' : 'liquid';

    return (
      <div>
        <h3>{cancer_category.tumor_type_humanized} Tumor</h3>
        <TumorTypeTraits options={tumor_type} tumor_type={tumor_type_key} onChange={(key, opt_id, e) => this.changeTumorTypeOption(tumor_type_key, key, opt_id, e)} />
      </div>
    )
  }

  renderTraits() {
    let {cancer_type} = this.state;
    let {query} = this.props;
    return (
      <div>
        <h3>Cancer Type Traits</h3>
        <CancerTypeTraits searchMode cancer_type={cancer_type} options={query.options} onChange={this.changeOption} />
      </div>
    )
  }

  renderDiagnostics() {
    let {cancer_type} = this.state;
    let {diagnostics_and_genes} = cancer_type;

    if (!diagnostics_and_genes || diagnostics_and_genes.length === 0) return null
    let diagnostics = diagnostics_and_genes.filter(i => i.type === 'Diagnostic');
    if (diagnostics.length === 0) return null;

    let {filters} = this.props;
    let cancer_type_diagnostics_and_genes = filters.cancer_type_diagnostics_and_genes || [];

    return (
      <div>
        <h3>Diagnostics</h3>
        <DiagnosticsAndGenes diagnostics_and_genes={diagnostics} cancer_type_diagnostics_and_genes={cancer_type_diagnostics_and_genes} onChange={this.onChangeDiagnosticsAndGenes} />
      </div>
    )
  }

  renderGenesAnalisis() {
    let {filters} = this.props;
    let fields = [
      {name: 'had_genomic_analysis', label: 'Has the patient had cancer genomic analysis / tumor sequencing on tumor tissue or liquid biopsy (circulating tumor DNA)?', type: 'select-boolean'}
    ]

    return <Form id="genomic_analysis" containerElement="div" fields={fields} model={filters} onChangeField={this.onChangeFilter} />
  }

  renderGenes() {
    let {cancer_type} = this.state;
    let {filters} = this.props;
    if (!filters.had_genomic_analysis) return null;

    let {diagnostics_and_genes} = cancer_type;

    if (!diagnostics_and_genes || diagnostics_and_genes.length === 0) return null
    let genes = diagnostics_and_genes.filter(i => i.type === 'Gene');
    if (genes.length === 0) return null;

    let cancer_type_diagnostics_and_genes = filters.cancer_type_diagnostics_and_genes || [];

    return (
      <div>
        <h3>Genes</h3>
        <DiagnosticsAndGenes diagnostics_and_genes={genes} cancer_type_diagnostics_and_genes={cancer_type_diagnostics_and_genes} onChange={this.onChangeDiagnosticsAndGenes} />
      </div>
    )
  }



  renderContent() {
    if (this.state.loading) return <Loading />
    if (!this.props.query.cancer_type_id || !this.state.cancer_type) return null;

    return (
      <div>
        {this.renderTraits()}
        {this.renderDiagnostics()}
        {this.renderGenesAnalisis()}
        {this.renderGenes()}
        {this.renderTumorType()}
      </div>
    );
  }

  render() {
    let {cancer_type_id} = this.props.query;
    return(
      <div id="by_cancer_type" className="search_panel">
        <div className="cancer_type_box">

          <Select2 id="select2_cancer_type" value={cancer_type_id} remote={true} src="/api/cancer_types/as_options.json" searchField="name_cont" onChange={e => this.cancerTypeChanged(+e.target.value)} />
        </div>
        {this.renderContent()}
      </div>
    )
  }

}
