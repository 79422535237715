import React, {Component} from 'react';

export default class DiagnosticsAndGenes extends Component {
  onChange(row, value) {
    let {cancer_type_diagnostics_and_genes, onChange} = this.props;
    cancer_type_diagnostics_and_genes = this.changeRow(cancer_type_diagnostics_and_genes, row, value);
    onChange(cancer_type_diagnostics_and_genes);
  }

  onChangeAll(current_row, all_rows, value) {
    let {cancer_type_diagnostics_and_genes, onChange} = this.props;

    let rows = all_rows.filter(r => r.id === current_row.id && r.type !== 'label');

    for (let row of rows) {
      cancer_type_diagnostics_and_genes = this.changeRow(cancer_type_diagnostics_and_genes, row, value);
    }
    onChange(cancer_type_diagnostics_and_genes);
  }


  changeRow(cancer_type_diagnostics_and_genes, row, value) {
    let option;
    let option_index = cancer_type_diagnostics_and_genes.findIndex(o => o.item.diagnostics_and_gene_id === row.id && o.item.any === row.any && ((row.type === 'Gene' && o.item.option === row.option_id) || o.item.result_id === row.option_id));

    if (option_index === -1) {
      let item = {diagnostics_and_gene_id: row.id, type: row.type, any: row.any};
      if (row.type === 'Gene') {
        item.option = row.option_id;
        item.id = `${row.id}:${row.option_id || ''}:gene`;
      } else {
        item.result_id = row.option_id;
        item.id = `${row.id}:${row.option_name || ''}:diagnostic`;
      }

      option = {item}
      cancer_type_diagnostics_and_genes.push(option);
    } else {
      option = cancer_type_diagnostics_and_genes[option_index];
    }

    option.selected = value;
    return cancer_type_diagnostics_and_genes;
  }

  buildRows() {
    let {diagnostics_and_genes, cancer_type_diagnostics_and_genes} = this.props;
    if (!diagnostics_and_genes) return null;

    let rows = [];
    for (let item of diagnostics_and_genes) {
      // First add the row with the label
      rows.push({id: item.id, key: 'label::' + item.id, type: 'label', name: item.name});

      // Add the row with any
      let any_option = cancer_type_diagnostics_and_genes.find(o => o.item.diagnostics_and_gene_id === item.id && o.item.any === true);
      let any_selected = any_option ? any_option.selected : 0;
      let any_label = item.type === 'Diagnostic' ? 'Any Result' : 'Any Alteration';
      rows.push({id: item.id, key: 'any::' + item.id, name: item.name + ' | '  + any_label, any: true, type: item.type, selected: any_selected});

      if (item.type === 'Gene' && item.data) {
        item.data.map(option => {
          let query_option = cancer_type_diagnostics_and_genes.find(o => o.item.diagnostics_and_gene_id === item.id && o.item.option === option.id);
          let selected = query_option ? query_option.selected : 0;
          rows.push({id: item.id, key: item.id + '::' + option.id, name: item.name + ' | ' + option.name + ' | Gene', option_id: option.id, option_name: option.name, type: item.type, selected})
        });
      } else if (item.results) {
        item.results.map(result => {
          let query_option = cancer_type_diagnostics_and_genes.find(o => o.item.diagnostics_and_gene_id === item.id && o.item.result_id === result.id);
          let selected = query_option ? query_option.selected : 0;

          rows.push({id: item.id, key: item.id + '::' + result.id, name: item.name + ' | ' + result.name + ' | Diagnostic', option_id: result.id, option_name: result.name, type: item.type, selected})
        });
      }
    }

    return rows;
  }

  renderRows() {
    let rows = this.buildRows();
    return rows.map(row => {
      if (row.type === 'label') {
      return (
        <tr key={row.key} className="panel-header-row">
          <td><strong>{row.name}</strong></td>
          <td style={{width: '30px'}}><button className="btn btn-link" onClick={e => this.onChangeAll(row, rows, 1)}>Yes</button></td>
          <td style={{width: '30px'}}><button className="btn btn-link" onClick={e => this.onChangeAll(row, rows, -1)}>No</button></td>
          <td style={{width: '40px'}}><button className="btn btn-link" onClick={e => this.onChangeAll(row, rows, 0)}>?</button></td>
        </tr>
      )
      } else {
        return (
          <tr key={row.key}>
            <td>{row.name}</td>
            <td style={{width: '30px'}}><input type="radio" checked={row.selected === 1} onChange={e => this.onChange(row, 1)}/></td>
            <td style={{width: '30px'}}><input type="radio" checked={row.selected === -1} onChange={e => this.onChange(row, -1)}/></td>
            <td style={{width: '30px'}}><input type="radio" checked={row.selected === 0} onChange={e => this.onChange(row, 0)}/></td>
          </tr>
        )
      }
    });
  }
  render() {
    return (
      <div className="search_panel_filter_holder">
        <table className="relevant_filter_table">
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }
}
