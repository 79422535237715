import React, {Component} from 'react';
import CancerTypeTraits from './search/cancer_type_traits';
import DiagnosticsAndGenes from './search/diagnostics_and_genes';
import TumorTypeQuestions from './tumor_type_questions';
import TumorTypeTraits from './search/tumor_type_traits';
import Form from './form';
import Loading from './loading';
import request from '../request';

export default class CancerDetailsEditor extends Component {
  constructor(props) {
    super(props);
    let {patient} = props;
    let cancer_types_count = patient.cancer_type_ids.length;
    let cancer_type_options = patient.data.cancer_type_options || {};

    this.state = {cancer_types_count, cancer_type_options};

    this.submit = this.submit.bind(this);
    this.updateContext = this.updateContext.bind(this);
    this.onChangeTrait = this.onChangeTrait.bind(this);
    this.onChangeDiagnosticsAndGenes = this.onChangeDiagnosticsAndGenes.bind(this);
    this.onChangeTumorTypeQuestion = this.onChangeTumorTypeQuestion.bind(this);
    this.onChangeTumorTypeTrait = this.onChangeTumorTypeTrait.bind(this);
    this.onChangeCurrentTypeOptions = this.onChangeCurrentTypeOptions.bind(this);
  }

  componentDidMount() {
    let {cancer_types_count} = this.state;

    if (cancer_types_count > 1) {
      this.fetchCancerTypeNames();
    } else if (cancer_types_count === 1) {
      let {patient} = this.props;
      this.state.current_cancer_type_id = patient.cancer_type_ids[0];
      this.fetchCancerTypeTraits(this.state.current_cancer_type_id).then(this.updateContext);
    }
  }

  submit() {
    let {patient} = this.props;
    let {cancer_type_options} = this.state;
    this.setState({submitting: true});
    patient.data.cancer_type_options = cancer_type_options;

    let url = "/admin/patients/" + patient.id
    request('PUT', url, {patient}).then(patient => {
      this.props.onChange(patient);
      this.setState({patient, submitting: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  fetchCancerTypeNames() {
    this.setState({loading: true});
    let {patient} = this.props;
    let cancer_type_ids = patient.cancer_type_ids.join(',');
    let url = '/admin/cancer_types/as_options?ids=' + cancer_type_ids;
    request('GET', url).then(({results}) => {
      this.setState({cancer_types: results, loading: false});
    });
  }

  fetchCancerTypeTraits(cancer_type_id) {
    let url = '/api/cancer_types/' + cancer_type_id;
    return request('GET', url).then(cancer_type => {
      let {cancer_type_options, cancer_types_data} = this.state;
      if (!cancer_types_data) {
        cancer_types_data = {};
      }

      cancer_types_data[cancer_type.id] = cancer_type;

      // Initialize the cancer type options
      if (!cancer_type_options[cancer_type_id]) {
        cancer_type_options[cancer_type_id] = {traits: {}, cancer_type_diagnostics_and_genes: [], tumor_type_questions: {}, tumor_type_traits: {}};
      }

      let ctx = defaultContext();
      this.setState({cancer_types_data, cancer_type_options, ctx, loading: false});
    });

  }

  selectCancerType(e) {
    let cancer_type_id = +e.target.value;
    if (!cancer_type_id || cancer_type_id === this.state.current_cancer_type_id) return;

    this.setState({current_cancer_type_id: cancer_type_id, loading: true});
    this.fetchCancerTypeTraits(cancer_type_id);
  }


  currentCancerTypeOptions() {
    let {cancer_type_options, current_cancer_type_id} = this.state;
    return cancer_type_options[current_cancer_type_id];
  }

  updateContext() {
    let {ctx, current_cancer_type_id} = this.state;
    let {patient} = this.props;
    let options = this.currentCancerTypeOptions();
    let url = `/admin/patients/${patient.id}/cancer_details_context`
    request('POST', url, {options, cancer_type_id: current_cancer_type_id}).then(ctx => {
      this.setState({ctx});
    });
  }

  onChangeTrait(trait, opt, event) {
    let {current_cancer_type_id, cancer_type_options} = this.state;

    let value = event.target.checked;
    let {query, onChange} = this.props;

    if (!cancer_type_options[current_cancer_type_id].traits[trait.id] || trait.option_type === 'S' || trait.stage_trait) {
      cancer_type_options[current_cancer_type_id].traits[trait.id] = {};
    }

    let opt_id = opt.id || opt;
    cancer_type_options[current_cancer_type_id].traits[trait.id][opt_id] = value;
    CancerTypeTraits.evaluateSmartEmtpyState(cancer_type_options[current_cancer_type_id].traits[trait.id], value);
    this.setState({cancer_type_options}, this.updateContext);
  }

  onChangeDiagnosticsAndGenes(cancer_type_diagnostics_and_genes) {
    let {current_cancer_type_id, cancer_type_options} = this.state;
    cancer_type_options[current_cancer_type_id].cancer_type_diagnostics_and_genes = cancer_type_diagnostics_and_genes;
    this.setState({cancer_type_options});
  }

  onChangeTumorTypeQuestion(tumor_type_questions) {
    let {current_cancer_type_id, cancer_type_options} = this.state;
    cancer_type_options[current_cancer_type_id].tumor_type_questions = tumor_type_questions;
    this.setState({cancer_type_options});
  }

  onChangeTumorTypeTrait(tumor_type, key, opt_id, event){
    let {current_cancer_type_id, cancer_type_options} = this.state;

    if (!cancer_type_options[current_cancer_type_id].tumor_type_traits) {
      cancer_type_options[current_cancer_type_id].tumor_type_traits = {}
    }
    let traits = cancer_type_options[current_cancer_type_id].tumor_type_traits;
    let value = event.target.checked;

    if (!traits[tumor_type]) {
      traits[tumor_type] = {}
    }

    if (!traits[tumor_type][key]) {
      traits[tumor_type][key] = {};
    }

    traits[tumor_type][key][opt_id] = value;
    CancerTypeTraits.evaluateSmartEmtpyState(traits[tumor_type][key], value);
    this.setState({cancer_type_options});
  }

  onChangeCurrentTypeOptions(key, value) {
    let {current_cancer_type_id, cancer_type_options} = this.state;
    cancer_type_options[current_cancer_type_id][key] = value;
    this.setState({cancer_type_options});
  }


  renderNoCancerTypesConfigured() {
    return (
      <div>
        <h3>No cancer types configured</h3>
        Add at least one cancer type to the patient
      </div>
    )
  }

  renderCancerTypePicker() {
    let {patient} = this.props;
    let {cancer_types_count, cancer_types, current_cancer_type_id} = this.state;
    if (cancer_types_count === 0) return this.renderNoCancerTypesConfigured();
    if (cancer_types_count === 1) return null;
    if (!cancer_types) return <Loading />

    return (
      <div>
        <label>Select the cancer type</label>
        <select onChange={e => this.selectCancerType(e)} value={current_cancer_type_id}>
          <option></option>
          {cancer_types.map(cancer_type => {
            return (
              <option key={cancer_type.id} value={cancer_type.id}>
              {cancer_type.name}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderTraits() {
    let {current_cancer_type_id, cancer_types_data, cancer_type_options} = this.state;
    let cancer_type = cancer_types_data[current_cancer_type_id];
    let current_cancer_type_options = cancer_type_options[current_cancer_type_id];

    return (
      <div className="cancer_details_form_section">
       <h3>Cancer Type traits</h3>
       <CancerTypeTraits cancer_type={cancer_type} options={current_cancer_type_options.traits} onChange={this.onChangeTrait} />
      </div>
    )
  }

  renderDiagnostics() {
    let {current_cancer_type_id, cancer_types_data, cancer_type_options} = this.state;

    let cancer_type = cancer_types_data[current_cancer_type_id];
    let current_cancer_type_options = cancer_type_options[current_cancer_type_id];

    let {diagnostics_and_genes} = cancer_type;
    if (!diagnostics_and_genes || diagnostics_and_genes.length === 0) return null
    let diagnostics = diagnostics_and_genes.filter(i => i.type === 'Diagnostic');
    if (diagnostics.length === 0) return null;

    let cancer_type_diagnostics_and_genes = current_cancer_type_options.cancer_type_diagnostics_and_genes;

    return (
      <div className="cancer_details_form_section">
        <h3>Diagnostics</h3>
        <DiagnosticsAndGenes diagnostics_and_genes={diagnostics} cancer_type_diagnostics_and_genes={cancer_type_diagnostics_and_genes} onChange={this.onChangeDiagnosticsAndGenes} />
      </div>
    )
  }


  renderGenesAnalisis() {
    let {current_cancer_type_id, cancer_types_data, cancer_type_options} = this.state;
    let cancer_type = cancer_types_data[current_cancer_type_id];
    let current_cancer_type_options = cancer_type_options[current_cancer_type_id];

    let fields = [
      {name: 'had_genomic_analysis', label: 'Has the patient had cancer genomic analysis / tumor sequencing on tumor tissue or liquid biopsy (circulating tumor DNA)?', type: 'select-boolean'}
    ]

    return <Form id="genomic_analysis" containerElement="div" fields={fields} model={current_cancer_type_options} onChangeField={this.onChangeCurrentTypeOptions} />
  }


  renderGenes() {
    let {current_cancer_type_id, cancer_types_data, cancer_type_options} = this.state;

    let cancer_type = cancer_types_data[current_cancer_type_id];
    let current_cancer_type_options = cancer_type_options[current_cancer_type_id];
    if (!current_cancer_type_options.had_genomic_analysis) return null;


    let {diagnostics_and_genes} = cancer_type;
    if (!diagnostics_and_genes || diagnostics_and_genes.length === 0) return null
    let genes = diagnostics_and_genes.filter(i => i.type === 'Gene');
    if (genes.length === 0) return null;

    let cancer_type_diagnostics_and_genes = current_cancer_type_options.cancer_type_diagnostics_and_genes;

    return (
      <div className="cancer_details_form_section">
        <h3>Genes</h3>
        <DiagnosticsAndGenes diagnostics_and_genes={genes} cancer_type_diagnostics_and_genes={cancer_type_diagnostics_and_genes} onChange={this.onChangeDiagnosticsAndGenes} />
      </div>
    )
  }

  renderTumorTypeQuestions() {
    let {current_cancer_type_id, cancer_types_data, cancer_type_options, ctx} = this.state;
    let cancer_type = cancer_types_data[current_cancer_type_id];
    if (cancer_type.cancer_category.tumor_type === 'L') return this.renderTumorTypeTraits();

    let current_cancer_type_options = cancer_type_options[current_cancer_type_id];
    let options = current_cancer_type_options.tumor_type_questions || {};
    return (
      <div className="cancer_details_form_section">
        <h3>Solid Tumor Traits</h3>
        <TumorTypeQuestions options={options} ctx={ctx} onChange={this.onChangeTumorTypeQuestion} />
      </div>
    )
  }

  renderTumorTypeTraits() {
    let {current_cancer_type_id, cancer_types_data, cancer_type_options} = this.state;
    let cancer_type = cancer_types_data[current_cancer_type_id];
    let tumor_type = cancer_type.cancer_category.tumor_type === 'S' ? 'solid' : 'liquid';

    let current_cancer_type_options = cancer_type_options[current_cancer_type_id];
    let options = current_cancer_type_options.tumor_type_traits || {};

    return (
      <div className="cancer_details_form_section">
        <h3>{cancer_type.cancer_category.tumor_type_humanized} Tumor</h3>
        <TumorTypeTraits options={options} tumor_type={tumor_type} onChange={(key, opt_id, e) => this.onChangeTumorTypeTrait(tumor_type, key, opt_id, e)} />
      </div>
    )
  }


  renderForms() {
    let {loading, submitting, current_cancer_type_id, cancer_types_data, cancer_type_options} = this.state;
    if (!current_cancer_type_id || !cancer_types_data) return null;
    if (loading) return <Loading />

    return (
      <div className="cancer_details_form_holder">
        {this.renderTraits()}
        {this.renderDiagnostics()}
        {this.renderGenesAnalisis()}
        {this.renderGenes()}
        {this.renderTumorTypeQuestions()}
        {Form.fakeSubmitButton({modelName: "Cancer Type Information", submitting:submitting, onSubmit: this.submit})}
      </div>
    )
  }
  render() {
    return (
      <div>
        {this.renderCancerTypePicker()}
        {this.renderForms()}
      </div>
    )
  }
}

function defaultContext() {
  return {};
}
