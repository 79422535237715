import React, {Component} from 'react';
import OpenAISummary from './openai_summary';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';

export default class ReferenceSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.onChangeOpenaiSummary = this.onChangeOpenaiSummary.bind(this);
    this.onOpenaiSummaryError = this.onOpenaiSummaryError.bind(this);
    this.onChangeOpenaiLoading = this.onChangeOpenaiLoading.bind(this);
  }

  componentDidMount() {
    this.fetchAISummary();
  }

  fetchAISummary() {
    let {reference_id, onContentLoaded} = this.props;
    let url = `/admin/references/${reference_id}/text_summary`;
    request('GET', url).then(({text_summary, ai_summary}) => {
      this.setState({text_summary, ai_summary, loading: false}, onContentLoaded)
    }).catch(error => {
      this.setState({loading: false});
    });
  }

  onChangeOpenaiLoading(loading) {
    let {ai_summary} = this.state;
    ai_summary.requesting_summary = loading;
    this.setState({ai_summary});
  }

  onChangeOpenaiSummary(summary) {
    let {ai_summary} = this.state;
    ai_summary.openai_summary = summary;
    ai_summary.update_required = false;
    this.setState({ai_summary});
  }

  onOpenaiSummaryError(message, code) {
    let {ai_summary} = this.state;
    ai_summary.openai_summary_error_message = message;
    ai_summary.openai_summary_error_code = code;
    this.setState({ai_summary});
  }

  renderNoAbstractText() {
    let {reference_id} = this.props;
    let url = `/admin/references/${reference_id}/edit`;
    return (
      <div>
        <strong>No abstract text set</strong>
        <p>Set abstract text to generate the summary</p>
        <a href={url} target="_blank">Edit Reference</a>
      </div>
    );
  }

  renderAbstractSummary() {
    let {text_summary} = this.state;

    return (
      <div className="formatted reference_styled_summary">
        <h4><b>ABSTRACT</b></h4>
        <Markdown text={text_summary} />
      </div>
    )
  }


  render() {
    let {reference_id} = this.props;
    let {loading, ai_summary, text_summary} = this.state;
    if (loading) return <Loading />
    if (!ai_summary) return null;
    if (!text_summary || text_summary.length === 0) {
      return this.renderNoAbstractText();
    }

    let url = `/admin/references/${reference_id}/openai_summary`

    return (
      <div>
        <div id="ai_summary">
          <OpenAISummary url={url} data={ai_summary} onChange={this.onChangeOpenaiSummary} onError={this.onOpenaiSummaryError} onChangeLoading={this.onChangeOpenaiLoading} />
        </div>

        {this.renderAbstractSummary()}
      </div>
    )
  }
}
