import React, {Component} from 'react';
import Form from './form';


const LABELS = {
  measurability: 'Does the patient have a measurable tumor on scan greater than 1 cm or a cancer associated lymph node greater than 1.5cm (i.e. qualify for Recist Criteria)?',
  visible_tumor: 'Is the patient with a visible tumor that can’t be measured easily (e.g. bone met, unusually shaped) or no tumor is obviously visible on scan (minimal residual disease or no evidence of disease)',
  removable: 'Can the cancer be completely removed with good margins (local, limited advanced, or oligometastatic)?',
  brain_or_spinal_metastases: 'Does the patient have brain or spinal cords metastases (not including small, asymptomatic brain mets)?',
  seeking_treatment_for_brain_mets: 'Is the patient specifically seeking treatments for brain mets?',
  treated_with_surgery_or_radiation: 'Has the patient been treated with surgery, radiation, or any systemic anti-cancer treatments (e.g. chemotherapy)?',
  tumor_grown_after_treatment: 'For the purpose of this search, has the patient or will the patient have experienced significant cancer growth during or after cancer treatment (called Progression, Recurrence, Relapse, or Refractory)?',

  has_surgery: 'Has the patient had surgery (not including biopsies) to remove most (debulking) or all (definitive) of the cancer?',
  has_prior_radiation: 'Has the patient had prior radiation treatment to some or all of the cancer?',
  treated_with_anti_cancer: 'Has the patient been treated with any systemic anti-cancer therapies (e.g. chemotherapy, targeted therapy, immunotherapy)',
  previously_received_therapies: 'For previously received systemic anti-cancer therapies (e.g. chemotherapy, targeted therapy, immunotherapy), choose all that apply',
  type_of_treatment: 'What Type of Treatment Does the Patient Seek?'
}

const HINTS = {
  tumor_grown_after_treatment: 'These 4 terms all indicate cancer that has grown, come back, or become resistant to treatment. This information is usually found in a recent clinic visit note from the medical records.'
}


const VISIBLE_TUMOR_OPTIONS = {
  yes: 'Yes, Visible',
  no: 'Not Visible',
  unknown: 'Unknown'
}

const PREVIOUSLY_RECEIVED_THERAPIES_OPTIONS = {
  neoadjuvant_before_surgery: 'Neoadjuvant therapy (treatment before surgery)',
  adjuvant_after_surgery: 'Adjuvant therapy (treatment after surgery)',
  treatment_for_advanced_for_metastatic: 'Treatment (chemo etc) for advanced or metastatic disease'
}

const TYPE_OF_TREATMENT_OPTIONS = {
  neoadjuvant_therapy: 'Neoadjuvant Therapy',
  definitive_surgery: 'Definitive Surgery or Radiation Treatment (e.g. complete resection, curative-intent radiation)',
  adjuvant_therapy: 'Adjuvant Therapy',
  maintenance: 'Maintenance: Pre-relapse Treatment, Post-Adjuvant Treatment, etc',
  line_1: '1st line (front-line advanced or metastatic or 1st non-local recurrence > 6 mo after adjuvant)',
  line_2: '2nd line',
  line_3: '3rd line',
  line_4: '4th line',
  line_5_plus: '5th line or higher'
}

const HAS_PRIOR_RADIATION_OPTIONS = {
  no: 'No',
  no_further_treatments:'Yes, but not interested in further radiation treatment',
  further_treatments: 'Yes, but potentially seeking further radiation treatment'
}

export default class TumorTypeQuestions extends Component {
  typeOfTreatmentOptions() {
    let {ctx} = this.props;
    if (!ctx || !ctx.stage || ctx.stage === 'advanced') return TYPE_OF_TREATMENT_OPTIONS;
    let options = {};
    let keys;

    if (ctx.stage === 'local') {
     keys = ['neoadjuvant_therapy', 'definitive_surgery', 'adjuvant_therapy', 'maintenance'];
    } else if (ctx.stage === 'metastatic') {
      keys = ['line_1', 'line_2', 'line_3', 'line_4', 'line_5_plus'];
    }

    for (let key of keys) {
      options[key] = TYPE_OF_TREATMENT_OPTIONS[key];
    }
    return options;
  }

  fields() {
    return [
      {name: 'measurability', label: LABELS['measurability'], type: 'select-boolean'},
      {name: 'visible_tumor', label: LABELS['visible_tumor'], type: 'select', collection: VISIBLE_TUMOR_OPTIONS, visible: o => o.measurability === false},
      {name: 'removable', label: LABELS['removable'], type: 'select-boolean', visible: o => o.measurability},
      {name: 'brain_or_spinal_metastases', label: LABELS['brain_or_spinal_metastases'], type: 'select-boolean'},
      {name: 'seeking_treatment_for_brain_mets', label: LABELS['seeking_treatment_for_brain_mets'], type: 'select-boolean', visible: o => o.brain_or_spinal_metastases},
      {name: 'treated_with_surgery_or_radiation', label: LABELS['treated_with_surgery_or_radiation'], type: 'select-boolean', no_label: 'No, newly diagnosed'},
      {name: 'tumor_grown_after_treatment', label: LABELS['tumor_grown_after_treatment'], hint: HINTS['tumor_grown_after_treatment'], type: 'select-boolean', visible: o => o.treated_with_surgery_or_radiation},
      {name: 'has_surgery', label: LABELS['has_surgery'], type: 'select-boolean', visible: o => o.treated_with_surgery_or_radiation},
      {name: 'has_prior_radiation', label: LABELS['has_prior_radiation'], type: 'select', collection: HAS_PRIOR_RADIATION_OPTIONS, visible: o => o.treated_with_surgery_or_radiation},

      {name: 'treated_with_anti_cancer', label: LABELS['treated_with_anti_cancer'], type: 'select-boolean', visible: o => o.treated_with_surgery_or_radiation},
      {name: 'previously_received_therapies', label: LABELS['previously_received_therapies'], type: 'checkboxes', collection: PREVIOUSLY_RECEIVED_THERAPIES_OPTIONS, visible: o => o.treated_with_anti_cancer},
      {name: 'type_of_treatment', label: LABELS['type_of_treatment'], type: 'select', collection: this.typeOfTreatmentOptions(), visible: o => o.treated_with_surgery_or_radiation !== false}
    ]
  }

  render() {
    let {options, onChange} = this.props;
    return <Form fields={this.fields()} model={options} onChange={onChange} />
  }
}
