import React, {Component} from 'react';
import * as Inflector from 'inflected';
import GroupsInformation from './groups_information';
import request from '../request';
import Loading from './loading';
import {getLocationLabel} from './locations';
import GeolocationInformation from './geolocation_information';
import joinReact from './join_react';
import TrialCollectionStarsRating from './trial_collection_stars_rating';
import StarsRatingField from './stars_rating_field';
import ReferenceStarsRating from './reference_stars_rating';

import {TrialLocationAvailabilityOptions, TrialLocationSourceOfVerificationOptions, ReferencePopulationRatingTypes} from '../constants.js.erb';


export default class CollectionTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleReferencesPanel() {
    let show_references = !this.state.show_references;
    this.setState({show_references});
  }

  copy() {
    let {collection_trial} = this.props;
    window.dispatchEvent(new CustomEvent('copy-trial', { detail: {collection_trial_id: collection_trial.id, trial_id: collection_trial.trial_id}}))
  }

  delete() {
    let confirmation = 'You will lose all associated data. You could move to another collection. Are you sure to remove?'
    if (!window.confirm(confirmation)) return;
    let {collection_trial, patient, onDelete} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection_trial.collection_id + "/trials/" + collection_trial.id;
    request('DELETE', url).then(r => {
      onDelete(collection_trial);
    });
  }

  moveTop() {
    let {collection_trial, patient, onMove} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection_trial.collection_id + "/trials/" + collection_trial.id + "/top";
    request('PUT', url).then(({collection_trial}) => {
      onMove(collection_trial);
    });
  }


  moveUp() {
    let {collection_trial, patient, onMove} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection_trial.collection_id + "/trials/" + collection_trial.id + "/up";
    request('PUT', url).then(({collection_trial}) => {
      onMove(collection_trial);
    });
  }

  moveDown() {
    let {collection_trial, patient, onMove} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection_trial.collection_id + "/trials/" + collection_trial.id + "/down";
    request('PUT', url).then(({collection_trial}) => {
      onMove(collection_trial);
    });
  }

  moveBottom() {
    let {collection_trial, patient, onMove} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection_trial.collection_id + "/trials/" + collection_trial.id + "/bottom";
    request('PUT', url).then(({collection_trial}) => {
      onMove(collection_trial);
    });
  }


  renderNoPatientTrialData() {
    return (
      <div>
        <hr/>
        <span className="subtle">No details provided yet.</span>
      </div>
    )
  }

  renderPatientTrialData(patient_trial_data) {
    if (!patient_trial_data) return this.renderNoPatientTrialData()
    return (
      <div className="patient_trial_details">
        <div>
          <h3>RATIONALE</h3>
          <p>
            <strong>{patient_trial_data.latest_rationale_status.type_humanized}</strong><br/>
            {patient_trial_data.latest_rationale_status.notes}
          </p>

          <p className="status_details">
            {patient_trial_data.latest_rationale_status.creation_label_humanized}
          </p>

        </div>
        <TrialCollectionStarsRating patient_trial_data={patient_trial_data} />
      </div>
    )
  }

  renderDropdown() {
    if (this.props.hideActions) return null;

    return (
      <div className="dropdown">
        <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fa fa-cog"></i> Actions
        </button>
        <ul className="dropdown-menu">
          <li><button className="btn btn-link" onClick={e => this.moveTop()}>Move Top</button></li>
          <li><button className="btn btn-link" onClick={e => this.moveUp()}>Move Up</button></li>
          <li><button className="btn btn-link" onClick={e => this.moveDown()}>Move Down</button></li>
          <li><button className="btn btn-link" onClick={e => this.moveBottom()}>Move Bottom</button></li>
          <li><button className="btn btn-link" onClick={e => this.delete()}>Remove From Collection</button></li>
          <li><button className="btn btn-link" onClick={e => this.copy()}>Copy to Another Collection</button></li>
        </ul>
      </div>
    );
  }

  renderLocationVerification(location) {
    if (!location.verified) return null;
    let date = moment(location.verified_at).format('LL')
    let notes, availability, source_of_verification;
    if (location.verified_notes) {
      notes = <span><b>Notes</b>: {location.verified_notes}</span>;
    }

    if (location.slot_availability) {
      let option = TrialLocationAvailabilityOptions.find(o => o.id === location.slot_availability);
      availability = <span><b>Availablility:</b> {option.name}</span>
    }

    if (location.source_of_verification) {
      let option = TrialLocationSourceOfVerificationOptions.find(o => o.id === location.source_of_verification);
      source_of_verification = <span>(Verified via {option.name})</span>
    }

    return (
      <>
        <p>{location.verified_by_name} verified on {date}</p>
        <p>{availability} {source_of_verification}</p>
        <p>{notes}</p>
      </>
    )
  }

  renderLocations() {
    let {collection_trial} = this.props;
    let {patient_trial_data, trial_id} = collection_trial;
    if (!patient_trial_data || !patient_trial_data.trial_locations || patient_trial_data.trial_locations.length === 0) return null;

    let locations = patient_trial_data.trial_locations.map(location => {
      let link = "/admin/trials/" + trial_id + "/locations/" + location.id + "/edit";
      let label = getLocationLabel(location, {show_zip: true, show_status: true});

      return (
        <div key={location.id} className="collection_show_location">
          <p><a  href={link} target="_blank">{label}</a></p>
          {this.renderLocationVerification(location)}
        </div>
      );
    })

    return (
      <div className="collection_show_locations_outer_holder" >
        <h4>LOCATIONS</h4>
        <div className="collection_show_locations_holder">
          {locations}
        </div>
      </div>
    )
  }

  renderPeople() {
    let {collection_trial} = this.props;
    let {patient_trial_data} = collection_trial;
    if (!patient_trial_data || !patient_trial_data.people || patient_trial_data.people.length === 0) return null;
    let people = patient_trial_data.people.map(p => {
      let link = "/admin/persons/" + p.id;
      return (
        <a key={p.id} href={link} target="_blank">{p.name}</a>
      );
    })

    return (
      <div className="collection_trial_entities">
        <p>
          <b>PEOPLE:&nbsp;</b>
          {joinReact(people, <span>&nbsp;|&nbsp;</span>)}
        </p>
      </div>
    )
  }

  renderReferences() {
    let {collection_trial} = this.props;
    let changelog, none_available_message, reference_panel, reference_target, reference_btn, references_content;

    if (collection_trial.latest_trial_reference_changelog) {
      let last = collection_trial.latest_trial_reference_changelog;
      let date = moment(last.date).format('LLL')
      changelog = <p className="changelog-item">On {date} {last.admin_name} checked for references and found no updates.</p>
    }

    if (!collection_trial.latest_trial_reference_changelog && collection_trial.trial_references.length < 1) {
      none_available_message = <p className="changelog-item">No reference information yet.</p>
    }
    if (collection_trial.trial_references.length > 0) {
      let {show_references} = this.state;
      let action = show_references ? 'Hide' : 'View'
      reference_btn = <button className="btn btn-sm btn-default" onClick={e => this.toggleReferencesPanel()}>{collection_trial.trial_references.length} Attached References - Click to {action}</button>
      if (show_references) {
        references_content = <ReferencesDetails trial_id={collection_trial.trial_id} />
      }
    }

    return (
      <div className="reference_display">
        <h4>References</h4>
        {reference_btn}
        {references_content}
        {changelog}
        {none_available_message}
      </div>
    )
  }

  render() {
    let {collection_trial, patient} = this.props;
    let collection_trial_path = "/admin/patients/" + patient.id + "/collections/" + collection_trial.collection_id + "/trials/" + collection_trial.id;
    return (
      <div className="collections_trial">
        <div className="collection_trial_details">
          <div className="collection_trial_title">
            <a href={collection_trial_path}>{collection_trial.trial_identifier}</a> | {collection_trial.trial.overall_status}<br/>
            <b> {collection_trial.trial_name} </b>
            <GroupsInformation data={collection_trial.groups_information} admin_mode={true} />
          </div>
          {this.renderDropdown()}
        </div>
        {this.renderPatientTrialData(collection_trial.patient_trial_data)}
        {this.renderLocations()}
        {this.renderPeople()}
        {this.renderReferences()}
        <GeolocationInformation patient={patient} trial_locations={collection_trial.trial_locations}/>
      </div>
    )
  }
}


class ReferencesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  componentDidMount() {
    this.fetchReferencesData();
  }

  fetchReferencesData() {
    let {trial_id} = this.props;
    let url = `/admin/trials/${trial_id}/references_data`;
    request('GET', url).then(references_data => {
      this.setState({references_data, loading: false});
    });
  }


  renderScoreableResultsData(reference) {
    return reference.populations.map(population => {
      if (!population.data.ratings || population.data.ratings.length === 0) return null;

      let population_link = `/admin/trials/${population.trial_id}/populations/${population.id}`;
      return (
        <div key={population.id}>
          <p className="pop_name"><a href={population_link}>{population.name}</a></p>
          <div className="reference_population_rating">
            <div className="rating_type"></div>
            <div className="rating_stars"><b>Efficacy</b></div>
            <div className="rating_stars"><b>Safety</b></div>
          </div>
          {population.data.ratings.map((rating, index) => {
            let rating_type = ReferencePopulationRatingTypes[rating.rating_type];
            let item_names = rating.items.map(i => i.name).join(', ');

            return (
              <div key={index} className="reference_population_rating">
                <div className="rating_type">
                  <strong>{rating_type}</strong><br />
                  {item_names}
                </div>
                <div className="rating_stars">
                  <StarsRatingField value={rating.efficacy_rating} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} separateExtraOptions={true} />
                  <p className="rating_description_text">{rating.efficacy_description}</p>
                </div>
                <div className="rating_stars">
                  <StarsRatingField value={rating.safety_rating} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} separateExtraOptions={true} />
                  <p className="rating_description_text">{rating.safety_description}</p>
                </div>
              </div>
            )
          })}
        </div>
      )
    });
  }

  renderNotableResultsData(reference) {
    return reference.populations.map(population => {
      if (!population.data.notable_information || population.data.notable_information.length === 0) return null;
      let population_link = `/admin/trials/${population.trial_id}/populations/${population.id}`;
      return (
        <div key={population.id}>
          <p className="pop_name"><a href={population_link}>{population.name}</a></p>
          <p>
            {population.data.notable_information}
          </p>
        </div>
      )
    });
  }

  renderResultsData(reference) {
    if (reference.populations.length === 0) return null;
    let content;
    if (reference.results_data === 'y') {
      content = this.renderScoreableResultsData(reference);
    } else if (reference.results_data === 'n') {
      content = this.renderNotableResultsData(reference);
    }

    return (
      <div className="reference_population_rating_container">
        <h3>Populations</h3>
        {content}
      </div>
    );
  }

  renderReference(reference) {
    let url = "/admin/references/" + reference.id;
    let date = moment(reference.created_at).format('LL');
    let reference_link;

    if (reference.source_link) {
      reference_link = <a href={reference.source_link} target="_blank">{reference.source_link} <i className="fa fa-external-link-alt"></i></a>
    }
    return (
      <div key={reference.id}>
        <a href={url} target="_blank">{reference.full_name}</a><br/>
        {reference_link}<br/>
        <span className="subtle">Added: {date} </span>
        {this.renderResultsData(reference)}
      </div>
    )
  }

  render() {
    let {loading, references_data} = this.state;
    if (loading) return <Loading />;

    return (
      <div>
        {references_data.map(r => this.renderReference(r))}
      </div>
    )
  }
}
