import React, {Component} from 'react';

export default class CancerTypeTraits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // https://trello.com/c/7BTDnUOj/696-search-improvements-handling-include-populations-without-details-for-this-field-in-search
  static evaluateSmartEmtpyState(collection, value) {
    let keys = Object.keys(collection)
    let checked_keys= keys.filter(id => id !== '_empty' && collection[id]);

    if (value && checked_keys.length === 1) {
      collection['_empty'] = true;
    } else if (!value && checked_keys.length === 0) {
      collection['_empty'] = false;
    }
  }


  toggleNonEssentialTraits() {
    let show_non_essentials_traits = !this.state.show_non_essentials_traits;
    this.setState({show_non_essentials_traits});
  }

  renderRadioTrait(trait) {
    let {options, onChange} = this.props;
    let name = traitName(trait);
    return (
      <div className="form-group" key={"trait_" + trait.id}>
        <label className="control-label">{name}</label>
        {trait.options.map(opt => {
          let key="trait_" + trait.id + "_opt_" + opt.id;
          let value = (options[trait.id] && options[trait.id][opt.id]) || false
          let name = optionName(opt);

          return (
            <div className="radio" key={key}>
              <label>
                <input type="radio" checked={value} onChange={e => onChange(trait, opt, e)}/> {name}
              </label>
            </div>
          )
        })}
      </div>
    );
  }

  renderNoDetailsOption(trait) {
    let {searchMode, options, onChange} = this.props;
    if (!searchMode) return null;

    let no_details_value = (options[trait.id] && options[trait.id]["_empty"]) || false
    return (
      <div className="checkbox select-empty">
        <label>
          <input type="checkbox" onChange={e => onChange(trait, "_empty", e)} checked={no_details_value} />
          <i>Include results without this specified</i>
        </label>
      </div>
    )
  }

  renderCheckboxTrait(trait) {
    let {options, onChange} = this.props;

    let option_elements = trait.options.map(opt => {
      let key="trait_" + trait.id + "_opt_" + opt.id;
      let value = (options[trait.id] && options[trait.id][opt.id]) || false
      let name = optionName(opt);
      return (
        <div className="checkbox" key={key}>
          <label>
            <input type="checkbox" checked={value} onChange={e => onChange(trait, opt, e)}/> {name}
          </label>
        </div>
      )
    });


    let name = traitName(trait);
    return (
      <div className="form-group" key={"trait_" + trait.id}>
        <label className="control-label">{name}</label>

        {this.renderNoDetailsOption(trait)}
        {option_elements}

      </div>
    );
  }

  renderTrait(trait) {
    let {searchMode} = this.props;
    if (searchMode) return this.renderCheckboxTrait(trait);
    if (trait.option_type === 'S' || trait.stage_trait) return this.renderRadioTrait(trait);
    else return this.renderCheckboxTrait(trait);
  }

  renderEssentialTraits(traits) {
    return (
      <div>
        {traits.map(trait => this.renderTrait(trait))}
      </div>
    );
  }

  renderNonEssentialTraits(traits) {
    if (traits.length === 0) return null;

    let {show_non_essentials_traits} = this.state;
    let content, togggle_btn_label;
    if (show_non_essentials_traits ) {
      content = traits.map(trait => this.renderTrait(trait));
      togggle_btn_label = 'Hide';
    } else {
      togggle_btn_label = 'Show';
    }

    return (
      <div>
        <button className="traits_toggle_button" onClick={e => this.toggleNonEssentialTraits()}>
          <b>{togggle_btn_label} Non Essential Traits</b>
        </button>
        {content}
      </div>
    );

  }


  render() {
    let {cancer_type} = this.props;
    let essential_traits = []
    let non_essential_traits = []
    for (let trait of cancer_type.traits) {
      let target =  trait.essential ? essential_traits : non_essential_traits;
      target.push(trait);
    }

    return (
      <div>
        {this.renderEssentialTraits(essential_traits)}
        {this.renderNonEssentialTraits(non_essential_traits)}
      </div>
    );
  }
}

function traitName(trait) {
  if (trait.search_side_label && trait.search_side_label.length > 0) {
   return trait.search_side_label;
  } else {
    return trait.name;
  }
}

function optionName(opt) {
  if (opt.search_side_label && opt.search_side_label.length > 0) {
    return opt.search_side_label;
  } else {
    return opt.text;
  }
}
