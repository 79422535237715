// TODO: Is this used?
import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {TumorTypes, CancerTypeSearchKey} from '../constants.js.erb';
import TraitsEditor from './traits_editor';

export default class PatientCancerTypeForm extends Component {
  constructor(props) {
    super(props);
    let {patient_cancer_type} = props;
    this.state = {patient_cancer_type, errors: {}};
    this.onChange = this.onChange.bind(this);
    this.onChangeOptions = this.onChangeOptions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeTraits = this.onChangeTraits.bind(this);
  }

  onSubmit(e) {
    this.setState({submitting: true});
    let {patient_cancer_type} = this.state;
    let action, url;
    if (patient_cancer_type.id) {
      action = 'PUT';
      url = '/admin/patient_cancer_types/' + patient_cancer_type.id;
    } else {
      action = 'POST';
      url = '/admin/patient_cancer_types';
    }

    request(action, url, {patient_cancer_type}).then(patient_cancer_type => {
      this.setState({patient_cancer_type, submitting: false});
      window.location = '/admin/patient_cancer_types/';
    }).catch(({errors}) => {
      this.setState({submitting: false, errors});
    });
  }

  onChange(patient_cancer_type) {
    this.setState({patient_cancer_type});
  }


  onChangeTraits(traits) {
    let {patient_cancer_type} = this.state;
    patient_cancer_type.traits = traits;
    this.setState({patient_cancer_type});
  }

  onChangeOptions(options) {
    let {patient_cancer_type} = this.state;
    patient_cancer_type.options = options;
    this.setState({patient_cancer_type});
  }

  formFields() {
    let {patient_cancer_type} = this.state;
    let {staging_options, tumor_sizes, nodal_spreads, metastases} = patient_cancer_type.options;

    return [
      {name: 'name', required: true},
      {name: 'tumor_type', required: true, type: 'radio', collection: TumorTypes},
      {name: 'cancer_type_id', label: 'Cancer Type', required: true, hint: 'What population related cancer type is most closely associated with this cancer type?', type: 'remote-select2', src: "/admin/cancer_types/as_options.json", search_key: CancerTypeSearchKey},
    ]
  }

  optionsFormFields() {
    return [
      {name: 'staging_options', type: 'select2', multiple: true, label: 'Applicable Staging Options', collection: STAGING_OPTIONS},
      {name: 'tumor_sizes', type: 'select2', multiple: true, label: 'Applicable Tumor (T) Size and extent', collection: TUMOR_SIZES},
      {name: 'nodal_spreads', type: 'select2', multiple: true, label: 'Applicable Nodal (N) Spreads', collection: NODAL_SPREADS},
      {name: 'metastases', type: 'select2', multiple: true, label: 'Applicable Metastases (M)', collection: METASTASES},
      {name: 'grades', type: 'select2', multiple: true, label: 'Applicable Grade', collection: GRADES}
    ]
  }

  renderSubmitButton() {
    let {submitting} = this.state;
    if (submitting) {
      return <button className="btn btn-success btn-lg" disabled={true}><i className="glyphicon glyphicon-refresh glyphicon-spin" /> Submitting Patient Cancer Type</button>
    } else {
      return <button className="btn btn-success btn-lg" onClick={this.onSubmit}>Submit Patient Cancer Type</button>
    }
  }
  render() {
    let {patient_cancer_type, errors} = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="well">
              <Form fields={this.formFields()} model={patient_cancer_type} errors={errors} onChange={this.onChange} />
              <Form fields={this.optionsFormFields()} model={patient_cancer_type.options} errors={errors} onChange={this.onChangeOptions} />
            </div>
          </div>
          <div className="col-md-6">
            <p><b>Custom Traits for this Cancer Type</b></p>
            <p className="small-text">These may not be required, but if this cancer type has additonal traits to record as part of adding a cancer diagnosis in journey tracker, they can be managed here.</p>
            <TraitsEditor traits={patient_cancer_type.traits} onChange={this.onChangeTraits} />
          </div>
        </div>
        <hr/>
        {this.renderSubmitButton()}
        <hr/>
      </div>
    );
  }
}

const STAGING_OPTIONS = ['I', 'IA', 'IB', 'II', 'IIA', 'IIB', 'III', 'IIIA', 'IIIB', 'IV', 'IVA', 'IVB', 'Limited (I-IIIB)', 'Extensive (IIB-IV)', 'Stage I (Early)', 'Stage II (Early)', 'Stage III (Advanced)', 'Stage IV (Metastatic)', '0', '1A', '1B', 'IIIC', '0 (Tis; Locoregional)', 'I (Locoregional)', 'IIA (Locoregional)', 'IIB (Locoregional)', 'IIIA (Locoregional)', 'IIIB (Advanced)', 'IIIC (Advanced)', 'IV (Metastatic)', 'Locoregional', 'Advanced', 'Metastatic', '0a', '0is', 'IIC', 'IVC', 'IIIC1', 'IIIC2', 'IC', 'IC1', 'IC2', 'IC3', 'IIIA1', 'IIIAIi', 'IIIIAIii', 'IIIA2', 'IIID']

const TUMOR_SIZES = ['TX', 'T0', 'Tis', 'T1', 'T1mi', 'T1a', 'T1b', 'T1c', 'T2', 'T2a', 'T2b', 'T3', 'T4', 'Ta', 'T3a', 'T3b', 'T4a', 'T4b', 'T2c', 'T1c1', 'T1c2', 'T1c3', 'T3c']
const NODAL_SPREADS = ['NX', 'N0', 'N1', 'N2', 'N3', 'N1a', 'N1b', 'N1c', 'Nx', 'No', 'N2a', 'N2b', 'N1mi', 'N2mi', 'N2c', 'N3a', 'N3b', 'N3c'];
const METASTASES = ['M', 'M0', 'M1', 'M1a', 'M1b', 'M1c', 'M1d'];
const GRADES = ['GX', 'G1', 'G2', 'G3', 'G4', 'G5'];
